import requestSender from "@/api/base/requestSender";

export const ProcedureEditMixin = {
  props: {
    procedure: {type: Object, required: true},
  },

  methods: {
    getEvaluations() {
      requestSender('get', 'evaluation/search', {})
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },
    getMeta() {
      this.loading = true;
      requestSender('get', 'compensation-procedure/get-meta', {
        id: this.procedure.id,
      })
        .then(data => {
          this.meta = data.procedure_meta;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveMeta() {
      this.loading = true;
      requestSender('post', 'compensation-procedure/save-meta', {
        id: this.procedure.id,
        meta: this.meta,
      })
        .finally(() => {
          this.loading = false;
        });
    },

    recountProcedure(callback){
      this.loading = true;
      requestSender('post', 'compensation-procedure/recount', {
        id: this.procedure.id,
      })
        .finally(() => {
          this.getMeta();
          if (typeof callback === 'function') {
            callback();
          }
        });
    },
  }
}