<template>
  <el-card style="margin-top: 30px">
    <div style="display: flex;justify-content: space-between">
      <h3 style="margin-top: 0; margin-bottom: 0">Настройки расчета годовой премии</h3>
      <el-button
        size="mini"
        icon="fas fa-calculator"
        :loading="loading"
        type="primary"
        @click="recountProcedure"
      >
        Рассчитать значения
      </el-button>
    </div>
    <h4 style="margin-bottom: 5px">Индивидуальный мультипликатор</h4>

    <table
      class="smz-base-table"
      v-loading="loading"
    >
      <thead>
      <tr>
        <th>Итоговая оценка эффективности деятельности</th>
        <th>Количество сотрудников</th>
        <th>Мультипликатор (Бенд < 50), %</th>
        <th>Мультипликатор (Бенд => 50), %</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="evaluation in evaluations[$companyConfiguration.settings.getEnum('evaluation_types')['TYPE_FINAL_EFFICIENCY']]">
        <td class="smz-base-table-cell-gray">{{ evaluation.name }}</td>
        <td class="smz-base-table-cell-gray">{{ meta.users_count && meta.users_count[evaluation.id] ? meta.users_count[evaluation.id] : 0 }}</td>
        <td>
          <click-to-edit
            v-if="meta.individual_multiplier"
            placeholder=""
            v-model="meta.individual_multiplier.less50[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-if="meta.individual_multiplier"
            placeholder=""
            v-model="meta.individual_multiplier.up50[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
      </tr>
      </tbody>
    </table>

    <div>
      <h4 style="display: inline-block; margin-top: 50px; margin-bottom: 5px">Таблица расчета финансового мультипликатора</h4>
      <el-button
        style="margin-left: 50px"
        size="mini"
        icon="fas fa-plus"
        @click="addKpi"
      >
        Создать КПЭ
      </el-button>
    </div>
    <div style="margin-bottom: 5px">Финансовый мультипликатор: {{meta.financial_multiplier}}%</div>
    <table
      class="smz-base-table"
      v-loading="loading"
    >
      <thead>
      <tr>
        <th>Группа показателей</th>
        <th>Номер КПЭ</th>
        <th>Показатель/задача</th>
        <th>Единица измерения</th>
        <th>Тип</th>
        <th>Min уровень, %</th>
        <th>Целевое значение показателя</th>
        <th>Max уровень, %</th>
        <th>Коэффициент выплаты</th>
        <th>Вес показателя, план</th>
        <th>Фактическое значение показателя</th>
        <th>% выполнения</th>
        <th>Вес показателя, факт, %</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(kpi, index) in meta.kpis"
      >
        <td>
          <click-to-edit
            v-model="kpi.group"
            placeholder=""
            :word-limit="255"
            show-word-limit
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          {{index+1}}
        </td>
        <td>
          <click-to-edit
            v-model="kpi.name"
            placeholder=""
            :word-limit="255"
            show-word-limit
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.unit"
            placeholder=""
            :word-limit="255"
            show-word-limit
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.type"
            placeholder=""
            :word-limit="255"
            show-word-limit
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.min_level"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.target"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.max_level"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.pay_factor"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.weight_plan"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.fact"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-model="kpi.fact_percent"
            placeholder=""
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          {{kpi.weight_fact}}
        </td>
        <td>
          <el-popconfirm
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить КПЭ?"
            @confirm="deleteKPI(index)"
          >
            <el-button
              style="margin-right: 10px"
              slot="reference"
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="far fa-trash-alt"
            >
            </el-button>
          </el-popconfirm>
        </td>
      </tr>
      </tbody>
    </table>

  </el-card>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import {ProcedureEditMixin} from "@/componentsCompany/smz/compensation/mixins/ProcedureEditMixin";

export default {
  name: "YearBonusTemplate",
  components: {ClickToEdit},
  mixins: [ProcedureEditMixin],
  props: {
    procedure: {type: Object, required: true},
  },

  data() {

    return {
      loading: false,

      evaluations: {},
      meta: {},


    }
  },

  mounted() {
    this.getEvaluations();
    this.getMeta();
  },
  methods: {

    addKpi() {
      this.meta.kpis.push({
        group: null,
        name: null,
        unit: null,
        type: null,
        min_level: null,
        target: null,
        weight_plan: null,
        fact: null,
        fact_percent: null,
        weight_fact: null,
      });
      this.saveMeta();
    },

    deleteKPI(index) {
      this.meta.kpis.splice(index, 1);
      this.saveMeta();
    },
  },


}
</script>

<style lang="scss">

</style>